import React from "react";
import {Link} from "gatsby";

function ArticleCardHome(props) {
    let tagsArray = [];
    let tags = props.article.frontmatter.tags;
    for (let i = 0; i < tags.length; i++) {
        tagsArray.push(<div key={i} className="bg-primary rounded-full px-[0.75rem] py-[0.375rem] text-white text-sm font-semibold max-w-fit inline-block mr-2">
            {tags[i].toUpperCase()}
        </div>)
    }

    var hoverEffects = "hover:drop-shadow-2xl hover:shadow-inner-lg hover:-translate-y-[4px]";  //
    // if (props.article.frontmatter.category === "SaaS"){
    //     hoverEffects += "hover:bg-orange hover:opacity-50";
    // }
    // else if (props.article.frontmatter.category === "NFT"){
    //     hoverEffects += "hover:bg-brown hover:opacity-50";
    // }
    // else if (props.article.frontmatter.category === "Crypto"){
    //     hoverEffects += "hover:bg-teal";
    // }

    const linkPath = "/" + props.article.slug;

    const containerClasses = "flex-1 flex-col bg-secondary border-x-4 border-b-4 border-primary px-6 py-6 " + hoverEffects;
    if (props.top) {
            return (
                <div className={containerClasses}>
                    {/*<Link to={linkPath}>*/}
                    <Link to="/coming-soon/">
                        <div className="absolute z-10 bg-teal w-6/6 h-6/6" />
                        <div className="flex flex-col space-y-10 h-full justify-center">
                            <h3 className="text-3xl font-semibold">{props.article.frontmatter.title}</h3>
                            <div>
                                {tagsArray}
                            </div>
                        </div>
                    </Link>
                </div>)
        }
    else {
            return (
                <div className={containerClasses}>
                    {/*<Link to={linkPath}>*/}
                    <Link to="/coming-soon/">
                        <div className="flex flex-col space-y-10 h-full justify-center">
                            <h3 className="text-3xl font-semibold">{props.article.frontmatter.title}</h3>
                            <div>
                                {tagsArray}
                            </div>
                        </div>
                    </Link>
                </div>
            )
        }
}

export default ArticleCardHome;