import React from "react"
import "../styles/global.css"
import {StaticImage} from "gatsby-plugin-image";
import {Link} from "gatsby";

import saasCloudIcon from "../images/saas-cloud.png";
import nftMonkeyIcon from "../images/nft-monkey.png";
import cryptoCoinsIcon from "../images/crypto-coins.png";
import topPanel from "../images/top-panel-resized.png";
import middlePanel from "../images/middle-panel.png";
import bottomPanel from "../images/bottom-panel.png";

function IntroCard () {
    return (
        <div className="flex mobile:flex-col tablet:flex-col">
            <div className="bg-white border-4 border-primary mobile:pb-8 desktop:border-r-0 flex-1 mobile:text-center tablet:text-center">
                {/*Desktop & Tablet HeaderCard*/}
                <div className="pt-6 desktop:pl-6 tablet:px-4 mobile:hidden">
                    <h1 className="text-[6.25rem] font-bold">FinTech</h1>
                    <h2 className="text-[2.5rem] font-semibold">Startup DIY Instructions</h2>
                </div>
                {/*Mobile HeaderCard*/}
                <h1 className="text-[6rem] font-bold tablet:hidden desktop:hidden">FinTech</h1>
                <h2 className="text-[3rem] font-bold mb-4 tablet:hidden desktop:hidden">Startup DIY Instructions</h2>

                <div className="flex tablet:w-auto desktop:w-auto desktop:pt-10 desktop:px-6 tablet:pt-10 tablet:pb-6 tablet:px-6 justify-center mobile:items-center mobile:space-x-4 tablet:space-x-8">
                    <div className="flex-initial flex flex-col items-center justify-end hover:drop-shadow-md">
                        <Link to="#saas">
                            <img className="min-h-content mobile:max-w-[100px] mb-4" src={saasCloudIcon} alt="A rain cloud representing SaaS" />
                            <h4 className="font-semibold text-xl text-orange text-center">SaaS</h4>
                        </Link>
                    </div>
                    <div className="flex-initial flex flex-col items-center justify-end hover:drop-shadow-md">
                        <Link to="#nft">
                            <img className="min-h-content mobile:max-w-[100px]" src={nftMonkeyIcon} alt="An ape representing popular NFTs" />
                            <h4 className="font-semibold text-xl text-brown text-center">NFTs</h4>
                        </Link>
                    </div>
                    <div className="flex-initial flex flex-col items-center justify-end hover:drop-shadow-md">
                        <Link to="#crypto">
                            <img className="min-h-content mobile:max-w-[100px]" src={cryptoCoinsIcon} alt="A pile of physical coins with the Ethereum logo on" />
                            <h4 className="font-semibold text-xl text-teal text-center ">Crypto</h4>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="flex-1 border-4 border-primary py-4 min-w-min mobile:hidden tablet:border-t-0">
                <div className="flex flex-col justify-center items-center tablet:space-y-8">
                    {/*<StaticImage src="../images/top-panel-resized.png" alt="Person building products using FlatPack FinTech" loading="eager" />*/}
                    {/*<StaticImage className="my-3" src="../images/middle-panel.png" alt="Person building products using FlatPack FinTech" loading="eager" />*/}
                    {/*<StaticImage src="../images/bottom-panel.png" alt="Person building products using FlatPack FinTech" loading="eager" />*/}
                    <img src={topPanel} alt="Person building products using FlatPack FinTech" />
                    <img className="my-3" src={middlePanel} alt="Person building products using FlatPack FinTech" />
                    <img src={bottomPanel} alt="Person building products using FlatPack FinTech" />
                </div>
            </div>
        </div>
    );
}

export default IntroCard;