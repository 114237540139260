import React from "react";
import "../styles/global.css";
import flatpackLogo from "../images/logo-box.svg";
import {Link} from "gatsby";

function Footer({children}) {
    return (
        <footer className="flex flex-col space-y-4 items-center py-7">
            {children}
            <Link to="/">
                <img className="icon" src={flatpackLogo} alt="Flatpack Fintech Logo of a box being opened" />
            </Link>
            <ul className="hidden lg:flex items-center w-auto space-x-12">
                <li><a href="#" className="text-md no-underline text-light hover:text-teal">About</a></li>
                <li><a href="#" className="text-md no-underline text-light hover:text-teal">Article</a></li>
                <li><a href="#" className="text-md no-underline text-light hover:text-teal">Courses</a></li>
                <li><a href="#" className="text-md no-underline text-light hover:text-teal">Log In</a></li>
                <li><a href="#" className="text-md no-underline text-light hover:text-teal">Sign Up</a></li>
            </ul>
            <div className="space-x-8 pb-6">
                <a href="https://twitter.com/flatpackfintech"><i className="fa-brands fa-twitter text-2xl text-midnight opacity-25 hover:opacity-100"></i></a>
                <a href="https://www.youtube.com/channel/UCbm4n2JTiJ2iXg2P4IcC9PA"><i className="fa-brands fa-youtube text-2xl opacity-25 hover:opacity-100"></i></a>
                <Link to="/"><i className="fa-brands fa-github text-2xl opacity-25 hover:opacity-100"></i></Link>
                <a href="https://www.linkedin.com/company/flatpack-fintech"><i className="fa-brands fa-linkedin text-2xl opacity-25 hover:opacity-100"></i></a>
            </div>
        </footer>
    )
}

export default Footer