import React from "react";
import {useStaticQuery, graphql, Link} from 'gatsby';
import {StaticImage} from "gatsby-plugin-image";

import saasCloudFull from "../images/saas-cloud-full.png";
import nftMonkeyFull from "../images/nft-monkey-full.png";
import cryptoCoinsFull from "../images/crypto-coins-full.png";

function CourseCardHome (props) {
    const stats = useStaticQuery(graphql`
    query CourseStats {
      saasCourses: allContentJson(filter: {category: {eq: "SaaS"}}) {
        nodes {
          requiredSkills {
            skill
          }
        }
        totalCount
      }
      saasArticles: allMdx(filter: {frontmatter: {category: {eq: "SaaS"}}}) {
        totalCount
      }
      
      nftCourses: allContentJson(filter: {category: {eq: "NFT"}}) {
        nodes {
          requiredSkills {
            skill
          }
        }
        totalCount
      }
      nftArticles: allMdx(filter: {frontmatter: {category: {eq: "NFT"}}}) {
        totalCount
      }
      
      cryptoCourses: allContentJson(filter: {category: {eq: "Crypto"}}) {
        nodes {
          requiredSkills {
            skill
          }
        }
        totalCount
      }
      cryptoArticles: allMdx(filter: {frontmatter: {category: {eq: "Crypto"}}}) {
        totalCount
      }
    }
    `)

    function getUniqueSkills(skills) {
        let uniqueSkills = [];
        for (let i = 0; i < skills.length; i++) {
            for (let j = 0; j < skills[i].requiredSkills.length; j++) {
                if (!uniqueSkills.includes(skills[i].requiredSkills[j].skill)) {
                    uniqueSkills.push(skills[i].requiredSkills[j].skill);
                }
            }
        }
        return uniqueSkills;
    }

    var productImg;
    var articleTotal;
    var courseTotal;
    let uniqueSkills = [];
    if (props.category === "SaaS") {
        productImg = <img className="group-hover:drop-shadow-2xl" src={saasCloudFull} alt="A raincloud representing the SaaS cloud" />;
        uniqueSkills = getUniqueSkills(stats.saasCourses.nodes);
        articleTotal = stats.saasCourses.totalCount;
        courseTotal = stats.saasArticles.totalCount;
    }
    else if (props.category === "NFT") {
        productImg = <img className="group-hover:drop-shadow-2xl" src={nftMonkeyFull} alt="An ape representing popular NFTs" />
        uniqueSkills = getUniqueSkills(stats.nftCourses.nodes);
        articleTotal = stats.nftCourses.totalCount;
        courseTotal = stats.nftArticles.totalCount;
    }
    else if (props.category === "Crypto") {
        productImg = <img className="group-hover:drop-shadow-2xl" src={cryptoCoinsFull} alt="Physical coins with Ethereum logo on" />
        uniqueSkills = getUniqueSkills(stats.cryptoCourses.nodes);
        articleTotal = stats.cryptoCourses.totalCount;
        courseTotal = stats.cryptoArticles.totalCount;
    }

    function renderCourseStats(desktop, uniqueSkills, articleCount, courseCount) {
        // Helper function for inserting value between every element of an array (without inserting at the end)
        function insertValue(arr, val) {
            let insertedArr = arr.map((i) => {
                return [i, val]
            }).flat();
            insertedArr.pop();
            return insertedArr;
        }

        const programming = {
            "python": <i className="fa-brands fa-python"></i>,
            "javascript": <i className="fa-brands fa-js-square"></i>,
            "web-development": <i className="fa-brands fa-html5"></i>};
        const blockchain = {
            "solidity": <i className="fa-brands fa-ethereum"></i>
        };
        const transferable = {
            "design": <i className="fa-solid fa-pen-ruler"></i>
        };

        function getSkillIcons(skills, categoryDict) {
            let skillLogos = [];
            for(let i = 0; i < Object.keys(categoryDict).length; i++) {
                if (skills.includes(Object.keys(categoryDict)[i])) {
                    skillLogos.push(categoryDict[Object.keys(categoryDict)[i]]);
                }
            }
            return skillLogos
        }

        if (desktop) {
        let renderedSkillCategories = [];
        // Check if one of unique skills in each of the categories
        if(uniqueSkills.some((val) => Object.keys(programming).indexOf(val) !== -1)) {
            let skillCategory = getSkillIcons(uniqueSkills, programming);
            if (skillCategory.length > 1) {
                skillCategory = insertValue(skillCategory, <p className="font-semibold whitespace-nowrap"> / </p>);
            }
            renderedSkillCategories.push(<div className="inline-flex items-end space-x-2 text-2xl ">
                {skillCategory}
            </div>);
        }

        if(uniqueSkills.some((val) => Object.keys(blockchain).indexOf(val) !== -1)) {
            let skillCategory = getSkillIcons(uniqueSkills, blockchain);
            if (skillCategory.length > 1) {
                skillCategory = insertValue(skillCategory, <p className="font-semibold whitespace-nowrap"> / </p>);
            }
            renderedSkillCategories.push(<div className="inline-flex items-end space-x-2 text-2xl ">
                {skillCategory}
            </div>);
        }

        if(uniqueSkills.some((val) => Object.keys(transferable).indexOf(val) !== -1)) {
            let skillCategory = getSkillIcons(uniqueSkills, transferable);
            if (skillCategory.length > 1) {
                skillCategory = insertValue(skillCategory, <p className="font-semibold whitespace-nowrap"> / </p>);
            }
            renderedSkillCategories.push(<div className="inline-flex items-end space-x-2 text-2xl ">
                {skillCategory}
            </div>);
        }

        if (renderedSkillCategories.length > 1) {
            renderedSkillCategories = insertValue(renderedSkillCategories,<p className="font-semibold whitespace-nowrap align-text-bottom"> + </p>);
        }

        return (
            <div className="flex rounded w-full bg-secondary py-2 px-2 justify-between">
                <div className="flex-col space-y-2 border-r-4 border-midnight/25 mr-4 pr-4 w-full">
                    <p className="font-semibold">The Toolbox:</p>
                    <div className="flex justify-center h-8 text-2xl space-x-4">
                        {renderedSkillCategories}
                    </div>
                </div>
                <div className="flex-col space-y-2 max-w-min min-w-[40%]">
                    <p className="font-semibold">What’s Inside:</p>
                    <div className="flex space-x-4 items-center h-8 text-2xl">
                        <p className="font-semibold text-2xl whitespace-nowrap"> <i className="fa-solid fa-graduation-cap"></i> x {courseCount}</p>
                        <p className="font-semibold text-2xl whitespace-nowrap"><i className="fa-solid fa-newspaper"></i> x {articleCount}</p>
                    </div>
                </div>
            </div>
        )
        }
        // Mobile / Tablet
        else {
            let renderedSkillCategories = [];
            if(uniqueSkills.some((val) => Object.keys(programming).indexOf(val) !== -1)) {
                let skillCategory = getSkillIcons(uniqueSkills, programming);
                if (skillCategory.length > 1) {
                    skillCategory = insertValue(skillCategory, <p className="font-semibold whitespace-nowrap"> / </p>);
                }
                renderedSkillCategories.push(<>
                    {skillCategory}
                </>);
            }
            if(uniqueSkills.some((val) => Object.keys(blockchain).indexOf(val) !== -1)) {
                let skillCategory = getSkillIcons(uniqueSkills, blockchain);
                if (skillCategory.length > 1) {
                    skillCategory = insertValue(skillCategory, <p className="font-semibold whitespace-nowrap"> / </p>);
                }
                renderedSkillCategories.push(<>
                    {skillCategory}
                </>);
            }
            if(uniqueSkills.some((val) => Object.keys(transferable).indexOf(val) !== -1)) {
                let skillCategory = getSkillIcons(uniqueSkills, transferable);
                if (skillCategory.length > 1) {
                    skillCategory = insertValue(skillCategory, <p className="font-semibold whitespace-nowrap"> / </p>);
                }
                renderedSkillCategories.push(<>
                    {skillCategory}
                </>);
            }
            if (renderedSkillCategories.length > 1) {
                renderedSkillCategories = insertValue(renderedSkillCategories,<p className="font-semibold whitespace-nowrap align-text-bottom"> + </p>);
            }

            return (
                <div className="flex flex-col space-y-4 text-2xl items-start mt-4 px-2 w-full">
                    <div className="flex space-x-4 items-center">
                        <p className="font-semibold">Toolbox:</p>
                        {renderedSkillCategories}
                    </div>
                    <div className="border-t-4 border-white opacity-50 w-full rounded-full" />
                    <div className="flex space-x-4 items-center">
                        <p className="font-semibold">What’s Inside:</p>
                        <p className="font-semibold text-2xl whitespace-nowrap"> <i className="fa-solid fa-graduation-cap"></i> x {courseCount}</p>
                        <p className="font-semibold text-2xl whitespace-nowrap"><i className="fa-solid fa-newspaper"></i> x {articleCount}</p>
                    </div>
                </div>
            )
        }
    }

    const courseStats = renderCourseStats(props.desktop, uniqueSkills, articleTotal, courseTotal);

    if (props.desktop) {
        const containerClasses = `group w-3/5 px-6 bg-${props.bgColour}`;
        return (
                <div className={containerClasses}>
                    <Link to="/courses/">
                        <div className="pt-6 pb-2">
                            <h2 className="text-[5rem] font-bold group-hover:text-white">{props.courseName}</h2>
                        </div>
                        <div className="flex justify-center pb-4">
                            {productImg}
                        </div>
                        <div className="mb-4 flex justify-between space-x-7 items-center">
                            {courseStats}
                        </div>
                    </Link>
                </div>
        )
    }
    else {
        const containerClasses = `group flex flex-col justify-center items-center text-center h-full pb-6 bg-${props.bgColour}`;
        return (
            <div className={containerClasses}>
                <Link to="/courses/">
                    <h1 className="text-[5rem] font-bold group-hover:text-white">{props.courseName}</h1>
                    {productImg}
                    {courseStats}
                </Link>
            </div>

        )
    }
}

export default CourseCardHome