import React from "react";
import NavigationBar from "../components/NavigationBar";
import IntroCard from "../components/IntroCard";
import CourseCardHome from "../components/CourseCardHome";
import NewsletterSignUp from "../components/NewsletterSignUp";
import ArticleCardHome from '../components/ArticleCardHome';
import Footer from "../components/Footer";
import {useStaticQuery, graphql} from 'gatsby';
import Layout from "../components/Layout";
import Seo from "../components/Seo";

const Index = () => {
    const articles = useStaticQuery(graphql`
    query FilteredArticles {
      saasHowTo: allMdx (limit:1,
            sort: { fields: [frontmatter___date], order: DESC },
            filter: {frontmatter: {category: {eq: "SaaS"} , tags: { eq: "How To"}}}){
      nodes {
          frontmatter {
              title
              date
              tags
              category
        }
        slug
      }
    }
      saasElse: allMdx (limit:2,
            sort: { fields: [frontmatter___date], order: DESC },
            filter: {frontmatter: {category: {eq: "SaaS"} , tags: { ne: "How To"}}}){
      nodes {
          frontmatter {
              title
              date
              tags
              category
        }
        slug
      }
    }

     nftHowTo: allMdx (limit:1,
            sort: { fields: [frontmatter___date], order: DESC },
            filter: {frontmatter: {category: {eq: "NFT"} , tags: { eq: "How To"}}}){
      nodes {
          frontmatter {
              title
              date
              tags
              category
        }
        slug
      }
    }
      nftElse: allMdx (limit:2,
            sort: { fields: [frontmatter___date], order: DESC },
            filter: {frontmatter: {category: {eq: "NFT"} , tags: { ne: "How To"}}}){
      nodes {
          frontmatter {
              title
              date
              tags
              category
        }
        slug
      }
    }

    cryptoHowTo: allMdx (limit:1,
            sort: { fields: [frontmatter___date], order: DESC },
            filter: {frontmatter: {category: {eq: "Crypto"} , tags: { eq: "How To"}}}){
      nodes {
          frontmatter {
              title
              date
              tags
              category
        }
        slug
      }
    }
    cryptoElse: allMdx (limit:2,
            sort: { fields: [frontmatter___date], order: DESC },
            filter: {frontmatter: {category: {eq: "Crypto"} , tags: { ne: "How To"}}}){
      nodes {
          frontmatter {
              title
              date
              tags
              category
        }
        slug
      }
    }
    }
    `)

    let articlesByCategory = {};
    if (articles.saasHowTo.nodes.length && articles.saasElse.nodes.length) {
        articlesByCategory['saas'] = [articles.saasHowTo.nodes[0], articles.saasElse.nodes[0]];
    }
    else {
        articlesByCategory['saas'] = [articles.saasElse.nodes[0], articles.saasElse.nodes[1]];
    }
    if (articles.nftHowTo.nodes.length && articles.nftElse.nodes.length) {
        articlesByCategory['nft'] = [articles.nftHowTo.nodes[0], articles.nftElse.nodes[0]];
    }
    else {
        articlesByCategory['nft'] = [articles.nftElse.nodes[0], articles.nftElse.nodes[1]];
    }
    if (articles.cryptoHowTo.nodes.length && articles.cryptoElse.nodes.length) {
        articlesByCategory['crypto'] = [articles.cryptoHowTo.nodes[0], articles.cryptoElse.nodes[0]];
    }
    else {
        articlesByCategory['crypto'] = [articles.cryptoElse.nodes[0], articles.cryptoElse.nodes[1]];
    }

    return (
        <Layout>
            <Seo title="FlatPack FinTech" />
            <NavigationBar />
            <IntroCard />
            {/* Desktop version - SaaS */}
            <section id="saas" className="flex min-h-96 mobile:hidden tablet:hidden">
                <CourseCardHome desktop courseName="SaåS" bgColour="orange" category="SaaS" />
                <div className="flex-1 flex flex-col">
                    <ArticleCardHome top article={articlesByCategory["saas"][0]} />
                    <ArticleCardHome top article={articlesByCategory["saas"][1]} />
                </div>
            </section>
            {/* Tablet & Mobile version - SaaS */}
            <section id="saas" className="desktop:hidden">
                <CourseCardHome courseName="SaåS" bgColour="orange" category="SaaS" />
                <ArticleCardHome article={articlesByCategory["saas"][0]} />
                <ArticleCardHome top article={articlesByCategory["saas"][1]} />
            </section>

            {/* Desktop version - NFTs */}
            <section id="nft" className="flex min-h-96 mobile:hidden tablet:hidden">
                <div className="flex-1 flex flex-col">
                    <ArticleCardHome article={articlesByCategory["nft"][0]} />
                    <ArticleCardHome top article={articlesByCategory["nft"][1]} />
                </div>
                <CourseCardHome desktop courseName="NFT" bgColour="brown" category="NFT" />
            </section>
            {/* Tablet & Mobile version - NFTs */}
            <section id="nft" className="desktop:hidden">
                <CourseCardHome courseName="NFT" bgColour="brown" category="NFT" />
                <ArticleCardHome article={articlesByCategory["nft"][0]} />
                <ArticleCardHome top article={articlesByCategory["nft"][1]} />
            </section>

            {/* Desktop version - Crypto */}
            <section id="crypto" className="flex min-h-96 mobile:hidden tablet:hidden">
                <CourseCardHome desktop courseName="Cryptö" bgColour="teal" category="Crypto" />
                <div className="flex-1 flex flex-col">
                    <ArticleCardHome article={articlesByCategory["crypto"][0]} />
                    <ArticleCardHome top article={articlesByCategory["crypto"][1]} />
                </div>
            </section>
            {/* Tablet & Mobile version - Crypto */}
            <section id="crypto" className="desktop:hidden">
                <CourseCardHome courseName="Cryptö" bgColour="teal" category="Crypto" />
                <ArticleCardHome article={articlesByCategory["crypto"][0]} />
                <ArticleCardHome top article={articlesByCategory["crypto"][1]} />
            </section>

            <NewsletterSignUp headline="Sign up to our newsletter" tagline="Stay ahead of the competition with the latest in all things FinTech" />
            <Footer>
                <a href="mailto:simon@flatpackfintech.com"><button className="px-6 py-2 mx-2 rounded-lg text-sm text-yellow font-bold leading-loose bg-[#c6b8ff] hover:bg-[#9488cc] hover:text-white">Say Hello 👋</button></a>
                <p className="text-lg">We’d love to hear from you</p>
            </Footer>
        </Layout>
    )
}

export default Index